export const FirebaseConfig = {
  apiKey: "AIzaSyBIVEcjGijukAWRHrfQdruHf2OVOOTPKec",
  authDomain: "combiendequartiers.firebaseapp.com",
  projectId: "combiendequartiers",
  storageBucket: "combiendequartiers.appspot.com",
  messagingSenderId: "6848169883",
  appId: "1:6848169883:web:2c33a6eaf9874e617c833e",
  measurementId: "G-LJMQKW45GQ",
  databaseURL: "https://combiendequartiers-default-rtdb.europe-west1.firebasedatabase.app/"
};
